<template>
  <div class="dis_center" style="width: 100%; height: 80px; min-width: 1200px; background-color: #f2f2f2; position: relative; z-index: 100;">
     <div class="top_con dis_around">
      <img :src="$imgSrc+ 'navlogo.png'" class="navlogo"/>

      <div class="dis" style="height: 100%;">
        <div class="dis_center" :class="nav_ac== item.id? 'nav_itemd': 'nav_item'"
        v-for="item, i in nav_list" :key="i" @click="c_nav(item)">
          {{ item.title }}
          <div class="ac">{{ item.name }}</div>
        </div>
      </div>
     </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      nav_list: [
        {
          title: '首页',
          name: 'HOME',
          id: 1,
          path: '/'
        },
        {
          title: '关于我们',
          name: 'ABOUT US',
          id: 2,
          path: 'aboutUs'
        },
        {
          title: '产品展示',
          name: 'PRODUCT DISPLAY',
          id: 3,
          path: 'productList'
        },
        {
          title: '食锁动态',
          name: "SS'S DYNAMICS",
          id: 4,
          path: 'dynamic'
        },
        {
          title: '联系我们',
          name: "CONTACT US",
          id: 5,
          path: 'contactUs'
        },
      ],
      nav_ac: 0,
    }
  },
  methods: {
    change_nav(){
      setTimeout(() => {
        console.log(this.$router.history)
        
        let obj= this.nav_list.filter(item=> {
          return item.path== this.$router.history.current.name || item.path== this.$router.history.current.path
        })[0]
        
        if(obj && this.nav_ac!= obj.id){
          this.nav_ac= obj.id
        }
        
      }, 500);
    },
    c_nav(item){
      if(item.id== this.nav_ac){
        return;
      }

      if(item.path){
        this.nav_ac= item.id
        this.$router.push(item.path)
      }

    },
    
  },

  mounted(){
    this.change_nav()

  },
  watch: {
    '$route': {
      handler(newVal, oldVal){
        this.change_nav()
      }
    }
  }

}
</script>

<style scoped>
  .navlogo{
    width: 84px;
	  height: 37px;
    flex-shrink: 0;
  }
  .top_con{
    width: 1200px;
	  height: 100%;
  }
  .nav_item,.nav_itemd{
    position: relative;
    width: 134px;
    height: 100%;
    flex-shrink: 0;
    cursor: pointer;
    color: #000;
    transition: all .2s;
    font-size: 16px;
  }
  .nav_item:hover{
    /* font-size: 16px; */
    font-weight: bold;
    text-shadow: 0 1px 6px rgba(0,0,0,.1);
  }
  .ac{
    width: 100%;
    position: absolute;
    top: 58%;
    left: 0;
    opacity: 0;
    transition: all .3s ease;
    text-align: center;
    zoom: .8;
    font-size: 12px;
    font-weight: 400;
  }
  .nav_item:hover .ac{
    top: 70%;
    opacity: 1;
  }

  .nav_itemd{
    font-weight: bold;
    text-shadow: 0 1px 6px rgba(0,0,0,.1);
  }
  .nav_itemd .ac{
    top: 70%;
    opacity: 1;
  }

</style>