import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pc/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: Home
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    meta: {
      title: '食锁动态'
    },
    component: () => import('@/views/pc/dynamic.vue')
  },
  {
    path: '/dynamicDetail',
    name: 'dynamicDetail',
    meta: {
      title: '动态详情'
    },
    component: () => import('@/views/pc/dynamicDetail.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/views/pc/contactUs.vue')
  },
  {
    path: '/jobList',
    name: 'jobList',
    meta: {
      title: '岗位招聘'
    },
    component: () => import('@/views/pc/jobList.vue')
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    meta: {
      title: '岗位招聘'
    },
    component: () => import('@/views/pc/jobDetail.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    meta: {
      title: '产品展示'
    },
    component: () => import('@/views/pc/productList.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/pc/aboutUs.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
