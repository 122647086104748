<template>
<div class="bottom_b" :style="{ backgroundColor: BGcolor }">
     <div class="fconb" :style="{ backgroundColor: BGcolor }">
          <div class="dis_between" style="height: calc(100% - 54px);">
               <div>
                    <div class="dis">
                         <el-image :src="$imgSrc+ 'foot1.png'" fit="cover" class="icon"></el-image>
                         <div class="f_14 l1">地址：哈尔滨市松北区鑫源街539号汇锦御江湾小区14号-15号商服</div>
                    </div>
                    <div class="dis" style="margin-top: 10px;">
                         <el-image :src="$imgSrc+ 'foot2.png'" fit="cover" class="icon"></el-image>
                         <div class="f_14 l1">邮箱：hrbsscy@163.com</div>
                    </div>
               </div>

               <div class="dis">
                    <div class="dis_column">
                         <div class="f_14" style="margin-bottom: 10px;">网络商城</div>
                         <div class="dis">
                              <el-image :src="$imgSrc+ 'web1.png'" fit="cover" class="web" style="margin-right: 14px;"></el-image>
                              <el-image :src="$imgSrc+ 'web2.png'" fit="cover" class="web"></el-image>
                         </div>
                    </div>

                    <div class="line"></div>

                    <div class="dis_column">
                         <div class="f_14" style="margin-bottom: 10px;">公众号</div>
                         <el-image :src="$imgSrc+ 'weim.png'" fit="cover" class="web"></el-image>
                    </div>

                    <div class="line"></div>

                    <div class="dis_column">
                         <div class="f_14" style="margin-bottom: 12px;">客服热线</div>
                         <div class="f_16 bold" style="color: #0071E3;">0451-82330005</div>
                    </div>

               </div>
          </div>
          <div class="f_13 c_999 dis_center bei">Copyright Right By 哈尔滨食锁餐饮管理有限公司 | 黑ICP备9289847090909</div>
     </div>
</div>
</template>

<script>
     export default {
          data(){
               return{
                    BGcolor: '#eeeeee'
               }
          },   
          
          watch: {
               '$route': {
                    handler(newval, old){
                         if(newval.name== 'home'){
                              this.BGcolor= '#fff'
                         }
                    }
               }
          }
     }
</script>

<style scoped>
     .bottom_b{
          width: 100%;
	     height: 224px;
          /* background-color: #eeeeee; */
     }
     .fconb{
          width: 1200px;
          min-width: 1200px;
	     height: 100%;
          margin: 0 auto;
          /* background-color: #eeeeee; */
     }

     .bei{
          border-top: 1px solid #c1c1c1;
          width: 100%;
	     height: 54px;
          box-sizing: border-box;
     }

     .icon{
          width: 28px;
	     height: 28px;
          margin-right: 10px;
     }
     .web{
          width: 46px;
	     height: 46px;
     }
     .line{
          width: 1px;
	     height: 50px;
          background-color: #c1c1c1;
          margin: 0 34px;
     }

</style>